<template>
  <div class="SafetyTask">
    <SafetyMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="项目名称">
          <el-select
            size="small"
            v-model="currentProject"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <!-- 功能按钮 -->
    <div class="wrap-blank">
      <el-button
        size="small"
        plain
        type="primary"
        icon="el-icon-plus"
        @click="newTask"
        >添加安检任务</el-button
      >
    </div>

    <!-- 数据列表 -->
    <div class="wrap">
      <el-table :data="taskPersonList" style="width: 100%">
        <el-table-column prop="id" label="编号" width="80"></el-table-column>
        <el-table-column prop="taskName" label="任务名称"></el-table-column>
        <el-table-column
          prop="pollingName"
          label="巡检负责人"
           width="150"
        ></el-table-column>
        <el-table-column prop="pollingPhone" label="联系电话" width="150"></el-table-column>
        <el-table-column
          prop="abarbeitungName"
          label="整改负责人"
           width="150"
        ></el-table-column>
        <el-table-column
          prop="abarbeitungPhone"
          label="联系电话"
           width="150"
        ></el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              size="mini"
              plain
              icon="el-icon-edit"
              @click="editTask(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="onDel(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="total, sizes, prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageNum"
        :page-sizes="[2, 5, 10, 20, 50, 100]"
        :total="totals"
        @current-change="getSafeTaskList"
        @size-change="getSafeTaskList"
      >
      </el-pagination>
    </div>

    <!-- 添加安检任务 -->
    <el-dialog
      title="安检任务"
      :visible.sync="showTask"
      style="width: 900px; margin: 0 auto"
    >
      <el-form inline label-width="120px">
        <el-form-item label="任务名称">
          <el-input
            size="mini"
            v-model="formTaskName"
            clearable
            placeholder="请输入任务名称"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="巡检负责人">
          <el-select
            size="mini"
            v-model="formPollingId"
            placeholder="请选择巡检负责人"
            clearable
            style="width: 200px"
          >
            <el-option
              v-for="item in pollingList"
              :key="item.id"
              :label="item.personName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input
            size="mini"
            v-model="formPollingPhone"
            readonly
            placeholder="巡检负责人联系电话"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="整改负责人">
          <el-select
            size="mini"
            v-model="formAbarbeitungId"
            placeholder="请选择巡检负责人"
            clearable
            style="width: 200px"
          >
            <el-option
              v-for="item in abarbeitungList"
              :key="item.id"
              :label="item.personName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input
            size="mini"
            v-model="formAbarbeitungPhone"
            readonly
            placeholder="整改负责人联系电话"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="线路选择">
          <el-select
            size="mini"
            v-model="formLineId"
            placeholder="请选择安检线路"
            clearable
            style="width: 200px"
          >
            <el-option
              v-for="item in lineList"
              :key="item.lineId"
              :label="item.lineName"
              :value="item.lineId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="安检日">
          <el-select
            size="mini"
            v-model="formPeriod"
            placeholder="请选择安检日"
            clearable
            style="width: 200px"
          >
            <el-option label="星期一" :value="1"></el-option>
            <el-option label="星期二" :value="2"></el-option>
            <el-option label="星期三" :value="3"></el-option>
            <el-option label="星期四" :value="4"></el-option>
            <el-option label="星期五" :value="5"></el-option>
            <el-option label="星期六" :value="6"></el-option>
            <el-option label="星期日" :value="7"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="showTask = false">取消</el-button>
        <el-button size="small" type="primary" @click="saveTask"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.SafetyTask {
  .el-form-item {
    margin-bottom: 0;
  }
  .fileIcon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .fileName {
    font-size: 14px;
    line-height: 16px;
  }
  .el-dialog__body {
    padding: 10px 20px;
  }
}
</style>

<script>
import SafetyMenu from "@/components/SafetyMenu.vue";

export default {
  name: "SafetyTask",
  components: {
    SafetyMenu,
  },
  data() {
    return {
      loading: null,
      projectList: [],
      currentProject: null,

      taskPersonList: [],
      pageNum: 1,
      pageSize: 10,
      totals: 0,

      pollingList: [], // 巡检人列表
      abarbeitungList: [], // 整改人列表

      lineList: [], // 巡检线路列表

      showTask: false,

      formId: "",
      formTaskName: "",
      formPollingId: "",
      formAbarbeitungId: "",
      formLineId: "",
      formPeriod: "",
    };
  },
  computed: {
    formPollingName: function () {
      var that = this;
      var personName = "";
      if (!this.formPollingId) {
        return personName;
      }
      this.pollingList.forEach((item) => {
        if (item.id == that.formPollingId) {
          personName = item.personName;
        }
      });
      return personName;
    },
    formPollingPhone: function () {
      var that = this;
      var personPhone = "";
      if (!this.formPollingId) {
        return personPhone;
      }
      this.pollingList.forEach((item) => {
        if (item.id == that.formPollingId) {
          personPhone = item.personPhone;
        }
      });
      return personPhone;
    },
    formAbarbeitungName: function () {
      var that = this;
      var abarbeitungName = "";
      if (!this.formAbarbeitungId) {
        return abarbeitungName;
      }
      this.abarbeitungList.forEach((item) => {
        if (item.id == that.formAbarbeitungId) {
          abarbeitungName = item.personName;
        }
      });
      return abarbeitungName;
    },
    formAbarbeitungPhone: function () {
      var that = this;
      var abarbeitungPhone = "";
      if (!this.formAbarbeitungId) {
        return abarbeitungPhone;
      }
      this.abarbeitungList.forEach((item) => {
        if (item.id == that.formAbarbeitungId) {
          abarbeitungPhone = item.personPhone;
        }
      });
      return abarbeitungPhone;
    },
  },
  mounted() {
    this.getProjectList(); // 加载项目列表数据
  },
  watch: {
    currentProject: function (newVal, oldVal) {
      this.getSafeTaskList();
      this.getPollingList();
      this.getAbarbeitungList();
      this.getLineList();
    },
  },
  methods: {
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        pageNum: 1,
        pageSize: 100,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id;
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载安检任务列表数据
    getSafeTaskList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: this.currentProject,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/safetyBackstageTask/inquireTask",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.taskPersonList = res.data.taskPersonList;
          that.totals = res.data.totals;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取巡检人列表数据
    getPollingList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: this.currentProject,
        safetyType: 1,
        pageNum: 1,
        pageSize: 10000,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/safetyBackstagePerson/inquireTaskPerson",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.pollingList = res.data.taskPersonList;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取整改人列表数据
    getAbarbeitungList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: this.currentProject,
        safetyType: 2,
        pageNum: 1,
        pageSize: 10000,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/safetyBackstagePerson/inquireTaskPerson",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.abarbeitungList = res.data.taskPersonList;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取巡检线路列表
    getLineList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: this.currentProject,
        pageNum: 1,
        pageSize: 10000,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/safetyLine/lineList",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.lineList = res.data.lineList;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 删除安检任务
    onDel: function (row) {
      var that = this;
      var data = {
        id: row.id,
      };
      this.$confirm("此操作将删除该巡检任务, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.loading = that.$loading({
            lock: true,
            text: "Loading...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          that
            .$axiosAdmin({
              method: "post",
              url: "api/admin/safetyBackstageTask/deleteTask",
              data: JSON.stringify(data),
            })
            .then((res) => {
              that.$message({
                type: "success",
                message: "操作成功!",
              });
              this.getSafeTaskList();
              that.loading.close();
            })
            .catch((err) => {
              console.log(err);
              that.loading.close();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 新建巡检任务
    newTask: function () {
      this.formId = "";
      this.formTaskName = "";
      this.formPollingId = "";
      this.formAbarbeitungId = "";
      this.formLineId = "";
      this.formPeriod = "";
      this.showTask = true;
    },
    // 编辑巡检任务
    editTask: function (row) {
      var that = this
      this.formId = row.id
      this.formTaskName = row.taskName
      this.formPollingId = parseInt(row.pollingId)
      this.formAbarbeitungId = parseInt(row.abarbeitungId)
      this.formLineId = row.lineId
      this.formPeriod = row.period
      this.showTask = true
    },
    // 保存巡检任务
    saveTask: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        id: this.formId,
        projectId: this.currentProject,
        lineId: this.formLineId,
        pollingId: this.formPollingId,
        abarbeitungId: this.formAbarbeitungId,
        taskName: this.formTaskName,
        pollingName: this.formPollingName,
        pollingPhone: this.formPollingPhone,
        abarbeitungName: this.formAbarbeitungName,
        abarbeitungPhone: this.formAbarbeitungPhone,
        period: this.formPeriod,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/safetyBackstageTask/insertTask",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.getSafeTaskList()
          that.showTask = false
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
  },
};
</script>