<template>
  <div class="safety-menu">
    <div class="main-menu">
      <router-link
        :to="{ name: 'SafetyDot' }"
        :class="[
          $route.name == 'SafetyDot' ? 'child-menu-active' : '',
        ]"
      >安检点</router-link>
      <router-link
        :to="{ name: 'SafetyLine' }"
        :class="[
          $route.name == 'SafetyLine' ? 'child-menu-active' : '',
        ]"
      >安检线路</router-link>
      <router-link
        :to="{ name: 'SafetyPerson' }"
        :class="[
          $route.name == 'SafetyPerson' ? 'child-menu-active' : '',
        ]"
      >安检人员</router-link>
      <router-link
        :to="{ name: 'SafetyTask' }"
        :class="[
          $route.name == 'SafetyTask' ? 'child-menu-active' : '',
        ]"
      >安检任务</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SafetyMenu',
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    menuPermission: function () {
      return JSON.parse(localStorage.getItem("menuPermission"));
    },
  },
}
</script>

<style lang="less" scoped>
@link-color: #2c85d8;

.safety-menu {
  width: 1200px;
  margin: 0 auto;
  a {
    display: inline-block;
    padding: 10px 20px;
  }
}
</style>
